import { Component, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'
import Notification from '@/modules/common/services/notification.service'
import { ToggleModulePayload } from 'gtr-types'

@Component({
  name: 'GtrEventModulesView',
  computed: {
    ...mapState('security', ['currentUser']),
    ...mapState('module', ['gtrModules', 'activatedEventModules'])
  }
})
export default class GtrEventModulesView extends Vue {
  currentUser!: Record<string, any>;
  gtrModules!: Record<string, any>;
  activatedEventModules!: Record<string, any>;

  data () {
    return {
      loadCurrentModulesStatus: 0,
      activated_event_modules: null
    }
  }

  created () {
    this.$bus.$on('gtr-module-activated', this.onModuleToggled())
    this.$bus.$on('gtr-module-deactivated', this.onModuleToggled())
  }

  async mounted () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.loadEventModules()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  goToModuleView (module: any) {
    const { uuid, event_uuid } = this.$route.params
    if (module.name !== 'Track') {
      this.$router.push({
        name: module.route.name,
        params: {
          uuid,
          event_uuid
        }
      })
    }
  }

  private async onModuleToggled () {
    await this.$store.dispatch('common/showLoader', { value: true })
    await this.loadEventModules()
    this.$bus.$emit('gtr-event-created')
    await this.$store.dispatch('common/hideLoader')
  }

  private async onModuleActivated (moduleIdentifier: string) {
    await this.onModuleToggled()
  }

  private async onModuleDeactivated (moduleIdentifier: string) {
    if (moduleIdentifier === 'SESSION_TRACKING' && this.isActive(this.getModuleById('SURVEYS'))) {
      // Until we move sessions to the event level, we have to deactivate Evals when Track is deactivated.
      const modulePayload: ToggleModulePayload = { event_uuid: this.$route.params.event_uuid, body: { module: 'SURVEYS' } }
      const response = await this.$store.dispatch('module/deactivateModule', modulePayload)
      if (/2\d\d/.test(response.status)) {
        Container.get(Notification).success('Module Evals & Surveys deactivated')
        this.$emit('gtr-module-deactivated', 'SURVEYS')
      } else {
        Container.get(Notification).error('Module Evals & Surveys failed to deactivate')
      }
    }
    await this.onModuleToggled()
  }

  private async loadEventModules () {
    try {
      this.$data.loadCurrentModulesStatus = 1
      const response = await this.$store.dispatch('event/fetchEventModules', this.$route.params.event_uuid)
      if (response) {
        this.$data.activated_event_modules = response
      }
      this.$data.loadCurrentModulesStatus = 2
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private getModuleById (id: string) {
    const [module] = this.gtrModules.filter((m: any) => m.identifier === id)
    return module
  }

  public isActive (module: any): boolean {
    return (this.activatedEventModules && this.activatedEventModules[module.identifier] && this.activatedEventModules[module.identifier].enabled) || false
  }

  public isAvailable (module: any): boolean {
    return (this.activatedEventModules && this.activatedEventModules[module.identifier] && module.ready) || false
  }

  public isActivatable (module: any): boolean {
    // Until we move sessions to the event level, Evals can only be activated when Track is active.
    switch (module.identifier) {
      case 'SURVEYS':
        return this.isActive(this.getModuleById('SESSION_TRACKING'))
      default:
        return true
    }
  }
}
